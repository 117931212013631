// @ts-strict-ignore
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { TextButton } from '@/core/TextButton.atom';
import { Icon } from '@/core/Icon.atom';
import { PathComponents } from '@/utilities/PathComponents.atom';
import { FakeLink } from '@/core/FakeLink';

export interface AssetReplaceModalProps {
  onAssetSelection: (item: { id: string }) => void;
  onClose: () => void;
  selectedAsset: { id: string; name: string } | undefined;
  availableAssets: any[];
  likelySwapOuts: { id: string; name: string; disabled: boolean }[];
  size: 'sm' | 'lg';
}

export const AssetReplaceModal: React.FunctionComponent<AssetReplaceModalProps> = ({
  onAssetSelection,
  onClose,
  selectedAsset,
  availableAssets,
  likelySwapOuts,
  size,
}) => {
  const [showFullPaths, setShowFullPaths] = useState(_.uniqBy(likelySwapOuts, 'name').length < likelySwapOuts.length);
  const hasSelectedAsset = !!selectedAsset;

  const { t } = useTranslation();

  return (
    <Modal size={size} show={true} onHide={onClose} animation={false} data-testid="assetReplaceModal">
      <Modal.Header closeButton={true}>
        <h4>{t('ASSET_REPLACE.TITLE')}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="pb10">
          {t('ASSET_REPLACE.PROMPT', {
            hasSelectedAsset,
            selectedAsset: selectedAsset.name,
          })}
        </div>
        {t('SWAPS.SHOW_FULL_PATH')}
        <span className="ml5 pl5 pr5">
          {showFullPaths ? (
            <a className="force-link-color" onClick={() => setShowFullPaths(false)}>
              <Icon icon="fa-toggle-on" extraClassNames="mr4" large={true} />
              {t('CONFIGURATION.ON')}
            </a>
          ) : (
            <a className="force-link-color" onClick={() => setShowFullPaths(true)}>
              <Icon icon="fa-toggle-off" extraClassNames="mr4" large={true} />
              {t('CONFIGURATION.OFF')}
            </a>
          )}
        </span>
        {showFullPaths
          ? _.map(availableAssets, (asset) => (
              <div className="pl10 pb10" key={asset.id}>
                <PathComponents
                  asset={asset}
                  actionOnComponent={() => {
                    onClose();
                    onAssetSelection(asset);
                  }}
                />
              </div>
            ))
          : _.map(likelySwapOuts, (asset) => (
              <div className="pl10 pb10" key={asset.id}>
                <FakeLink
                  extraClassNames="stronger nowrap"
                  onClick={() => {
                    onClose();
                    onAssetSelection(asset);
                  }}>
                  {asset.name}
                </FakeLink>
              </div>
            ))}
        <div className="text-center pt10">
          <TextButton label="CANCEL" onClick={onClose} size="sm" />
        </div>
      </Modal.Body>
    </Modal>
  );
};
